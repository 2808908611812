import type { AlertDialogProps } from '@chakra-ui/react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'

// AlertDialogPropsでchildrenが必須なのでOmitして不要化したPropsを定義
interface Props extends Omit<AlertDialogProps, 'children'> {
  title: string
  bodyMessage?: string
  children?: React.ReactNode
}

/**
 * useDialogからpropsを渡して使う。ダイアログ操作はuseDialogの関数で行う。
 * SEE: frontend/talent/hooks/useDialog.ts
 * Example: frontend/talent/components/registration/agent/RegistrationForTalentPageWrapper/RegistrationForTalentPageWrapper.tsx
 */
export const ErrorDialog: React.FC<Props> = ({ children, title, bodyMessage, ...props }) => {
  const { onClose } = props

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      // 渡されたpropsをリレーする。
      {...props}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent w="326px">
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogBody whiteSpace="pre-wrap">{children || bodyMessage}</AlertDialogBody>
        <AlertDialogFooter>
          <Button
            borderColor="text.link"
            color="text.link"
            height="40px"
            onClick={onClose}
            variant="outline"
            width="fit-content"
          >
            OK
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
