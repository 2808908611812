import { useEffect, useState } from 'react'
import { Flex, Link, Text, VStack } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import { ErrorDialog } from '@/components/parts/ErrorDialog'
import { appUrl } from '@/config'
import { useAuth } from '@/hooks/useAuth'
import { useDialog } from '@/hooks/useDialog'
import { useUserAgent } from '@/hooks/useUserAgent'
import { LINEButton } from '@/modules/components/LINEButton'
import { MainButton } from '@/modules/components/MainButton'
import { SmsButton } from '@/modules/components/SMSButton'
import { privacyPolicyPage } from '@/modules/constant'

import { LogoAndTitleElm } from '../parts/LogoAndTitleElm'

import { SMSVerification } from './SMSVerification'

export const LoginPageWrapper: React.FC = () => {
  const router = useRouter()
  const [isSMS, setIsSMS] = useState(false)
  const { sessionInitialize, lineLogin } = useAuth()
  const { errorDialogProps, openDialogWithTitleAndMessage } = useDialog()
  const { isWebView } = useUserAgent()

  useEffect(() => {
    // Laravelのセッションを開始する
    sessionInitialize()
    if (router.query.error === 'forbidden') {
      openDialogWithTitleAndMessage(
        'LINEでの登録情報はありません',
        '登録したアカウントをご確認のうえ再度ログインをお試しください',
      )
    }
  }, [])

  return (
    <Flex
      alignItems="center"
      bgColor="white"
      flexDirection="column"
      justifyContent="flex-start"
      m="0 auto"
      maxWidth="640px"
      minHeight="100vh"
    >
      <LogoAndTitleElm title="アカウントにログイン" />
      {isSMS && <SMSVerification router={router} />}
      <VStack p="24px 24px 40px" spacing="24px" w="100%">
        <VStack spacing="16px" w="100%">
          {!isSMS && <SmsButton id="sign-in-button" onClick={() => setIsSMS(true)} w="100%" />}
          {!isWebView && <LINEButton onClick={lineLogin} w="100%" />}
        </VStack>
        <Text color="text.primary" fontSize="11px" lineHeight="150%">
          続けることにより、あなたは
          <Link
            _hover={{ textDecoration: 'none' }}
            color="text.link"
            href={`${appUrl}/docs/terms`}
            target="_blank"
            textDecoration="none"
          >
            利用規約
          </Link>
          に同意し
          <Link
            _hover={{ textDecoration: 'none' }}
            color="text.link"
            href={privacyPolicyPage}
            target="_blank"
            textDecoration="none"
          >
            プライバシーポリシー
          </Link>
          を理解したうえでZキャリアを利用することに同意したものとみなします。
        </Text>
      </VStack>
      <VStack
        borderTop="1px solid var(--border-border-primary, #E2E8F0);"
        p="40px 24px"
        spacing="16px"
        w="100%"
      >
        <Text color="text.primary" fontSize="11px" lineHeight="150%">
          Zキャリアは初めてですか？
        </Text>
        <MainButton borderColor="border.primary" h="40px" mode="secondary" p="10px 24px" w="160px">
          <Text
            color="text.primary"
            fontSize="14px"
            fontWeight={700}
            lineHeight="20px"
            onClick={() => router.push('/registration/agent')}
          >
            会員登録（無料）
          </Text>
        </MainButton>
      </VStack>
      <ErrorDialog {...errorDialogProps} />
    </Flex>
  )
}
