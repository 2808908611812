import type { FC } from 'react'
import { useState } from 'react'
import { Box, Button, Flex, FormControl, Input, Text } from '@chakra-ui/react'
import type { NextRouter } from 'next/router'
import { toast } from 'sonner'

import { ErrorDialog } from '@/components/parts/ErrorDialog'
import { localStorageKey } from '@/const/localStorage'
import { SITE_URL } from '@/const/url'
import { firebaseAuth, useAuth } from '@/hooks/useAuth'
import { useDialog } from '@/hooks/useDialog'
import { useRedirectAfterLogin } from '@/hooks/useRedirectAfterLogin'
import { isAuthenticatedRoute } from '@/middleware'
import { MainButton } from '@/modules/components/MainButton'
import { isProd } from '@/modules/utils/env'
import { sendGANoValueEvent } from '@/modules/utils/gtm/gaEventActions'
import { checkNotSendSMS } from '@/utils/validation/checkNumber'

interface Props {
  router: NextRouter
}

export const SMSVerification: FC<Props> = ({ router }) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [confirmNumber, setConfirmNumber] = useState('')
  const [canResend, setCanResend] = useState(true)
  const { errorDialogProps, openDialogWithTitleAndMessage } = useDialog()
  const { getToUrl, clearRedirectUrl } = useRedirectAfterLogin()

  const {
    isConfirm,
    confirmFunc,
    submit: onSetPhoneNumber,
    resendVerificationCode,
    login,
    isVerifyLoading,
    setIsVerifyLoading,
    confirmError,
  } = useAuth()
  const onSubmitForPhoneNumber = () => {
    // 数字のみ入力されているかチェック
    if (!/^\d+$/.test(phoneNumber)) {
      toast.error('電話番号は数字のみで入力してください。')
      return
    }
    // 入力された電話番号の形式チェック
    if (checkNotSendSMS(phoneNumber, isProd)) {
      openDialogWithTitleAndMessage(
        'LINEで続けるをご利用ください',
        'ご入力いただいた電話番号ではSMSでの認証ができません。',
      )
      return
    }
    onSetPhoneNumber(phoneNumber)
  }
  const onSubmit = () => {
    setIsVerifyLoading(true)
    confirmFunc
      ?.confirm(confirmNumber)
      .then(async (res) => {
        const idToken = await res.user.getIdToken()
        await login(idToken)
          .then((status) => {
            if (status === 200) {
              sendGANoValueEvent('login')
              localStorage.setItem(localStorageKey.LOGIN_COMPLETE, 'true')
              const redirectUrl = getToUrl()
              clearRedirectUrl()
              if (isAuthenticatedRoute(redirectUrl) || redirectUrl === SITE_URL.ROOT) {
                window.location.href = redirectUrl
                return
              }
              router.push(redirectUrl).then(() => {
                setIsVerifyLoading(false)
              })
            }
            if (status === 401) {
              firebaseAuth.signOut()
              openDialogWithTitleAndMessage(
                '認証エラーが発生しました。',
                '画面を更新して再度お試しください。',
              )
              setIsVerifyLoading(false)
            }
            if (status === 403) {
              openDialogWithTitleAndMessage(
                'SMSでの登録情報はありません。',
                '登録したアカウントをご確認のうえ再度ログインをお試しください。',
              )
              setIsVerifyLoading(false)
            }
          })
          .catch((res) => {
            confirmError(res)
            setIsVerifyLoading(false)
          })
      })
      .catch((res) => {
        confirmError(res)
        setIsVerifyLoading(false)
      })
  }

  const resendSMS = () => {
    if (!canResend) {
      openDialogWithTitleAndMessage(
        'まだ再送信はできません',
        'SMS認証コードの再送信には、60秒以上の間隔が必要です。少し時間を置いてからお試しください。',
      )
      return
    }
    if (resendVerificationCode) {
      setCanResend(false)
      resendVerificationCode(phoneNumber)
      setTimeout(() => {
        setCanResend(true)
      }, 60000)
    } else {
      toast.error('認証コードの再送に失敗しました。画面を更新して再度お試しください。')
    }
  }

  return (
    <Box p="24px 24px 0px" w="100%">
      <Flex
        border="1px solid var(--border-border-primary, #E2E8F0)"
        borderRadius="8px"
        flexDirection="column"
        gap="24px"
        justifyContent="flex-start"
        p="16px"
      >
        <Text fontSize="16px" fontWeight={700} lineHeight="150%">
          電話番号（SMS）でログインする
        </Text>
        <Flex flexDirection="column" gap="8px" justifyContent="flex-start">
          <Text fontSize="13px" fontWeight={700} lineHeight="175%">
            {isConfirm ? '認証コードを入力してください' : '携帯電話番号を入力してください'}
          </Text>
          <Text fontSize="12px" fontWeight={300} lineHeight="150%">
            {isConfirm
              ? `${phoneNumber} 宛にSMSで送信された認証コードを入力してください。`
              : '本人確認のため、SMSでご連絡します。日本国内でSMSを受信できる番号をご入力ください。'}
          </Text>
          <FormControl>
            <Input
              _placeholder={{ color: '#A0AEC0' }}
              fontSize="18px"
              h="48px"
              lineHeight="28px"
              maxLength={11}
              onChange={
                isConfirm
                  ? (e) => setConfirmNumber(e.target.value)
                  : (e) => setPhoneNumber(e.target.value)
              }
              p="10px 16px"
              pattern="[0-9]*"
              placeholder={isConfirm ? '6桁の認証コードを入力' : '例）08012345678'}
              type={isConfirm ? 'text' : 'tel'}
              value={isConfirm ? confirmNumber : phoneNumber}
            />
          </FormControl>
          <MainButton
            id="sign-in-button"
            isLoading={isVerifyLoading}
            mode="primary"
            onClick={isConfirm ? onSubmit : onSubmitForPhoneNumber}
            type="submit"
            width="100%"
          >
            <Text fontSize="16px" fontWeight={700} lineHeight="20px">
              次に進む
            </Text>
          </MainButton>
        </Flex>
        {isConfirm && (
          <Button
            color="text.link"
            cursor="pointer"
            fontSize="11px"
            fontWeight={300}
            id="resend-button"
            lineHeight="150%"
            onClick={resendSMS}
            textAlign="center"
            variant="ghost"
          >
            認証コードを再送
          </Button>
        )}
      </Flex>
      <ErrorDialog {...errorDialogProps} />
    </Box>
  )
}
