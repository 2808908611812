import type { NextRequest } from 'next/server'
import { NextResponse } from 'next/server'

import { appEnv, sessionCookieName } from './config'

/**
 * 認証が必要なルート一覧
 */
export const AUTHENTICATED_ROUTES = [
  '/account/:path*',
  '/scout/:path*',
  '/inquiry/:path*',
  '/agent/interview/:path*',
  '/registration/scout', // WHY: STEP2登録ルート。STEP1完了=認証済みでないとアクセスできない
] as const

/**
 * 認証済みユーザーがアクセスできないルート一覧
 * ログインページなど、既にログインしているユーザーをリダイレクトさせるページを定義
 * FYI: STEP2登録判定はmiddlewareではできないため、ページコンポーネントでリダイレクトしている
 * @see ../pages/registration/scout.tsx
 */
export const PUBLIC_AUTH_ROUTES = ['/login', '/registration/agent', '/_dev/registration'] as const

/**
 * 認証が必要なルートかどうかを判定
 * @param pathname - パス名
 * @returns 認証が必要なルートかどうか
 */
export const isAuthenticatedRoute = (pathname: string): boolean => {
  if (pathname.startsWith('/job/detail/') && pathname.includes('inquiry')) return true
  return AUTHENTICATED_ROUTES.some((route) => pathname.startsWith(route.replace('/:path*', '')))
}

/**
 * 認証済みユーザーがアクセスできないルートかどうかを判定
 * @param pathname - パス名
 * @returns 認証済みユーザーがアクセスできないルートかどうか
 */
export const isPublicAuthRoute = (pathname: string): boolean => {
  return PUBLIC_AUTH_ROUTES.some((route) => pathname.startsWith(route.replace('/:path*', '')))
}

/**
 * 認証ミドルウェア
 * - Firebase AuthのsessionCookieの有無で認証済みかどうかを判定する、トークンの検証はここでは行わない
 * - 未認証ユーザーが認証ルートにアクセスした場合、ログインページにリダイレクト
 * - ログイン済みユーザーが会員登録やログインページにアクセスした場合、TOPにリダイレクト
 * - セッションがない場合は認証ルートにアクセスしている場合はログインページに遷移
 */
export async function middleware(request: NextRequest) {
  const { pathname, search } = request.nextUrl
  const session = request.cookies.get(sessionCookieName)

  if (!session) {
    if (isAuthenticatedRoute(pathname)) {
      const safeRedirect = pathname + search

      const response = NextResponse.redirect(
        new URL(`/login?redirect=${encodeURIComponent(safeRedirect)}`, request.url),
      )

      response.cookies.set('redirect_after_login', safeRedirect, {
        path: '/',
        httpOnly: false,
        secure: appEnv === 'production',
        sameSite: 'lax',
      })
      return response
    }
    return NextResponse.next()
  }

  if (isPublicAuthRoute(pathname)) {
    return NextResponse.redirect(new URL('/', request.url))
  }

  return NextResponse.next()
}

/**
 * ミドルウェアを適用、または除外するルートの設定
 */
export const config = {
  matcher: [
    /*
     * Match all request paths except for the ones starting with:
     * - api (API routes)
     * - _next/static (static files)
     * - _next/image (image optimization files)
     * - favicon.ico, sitemap.xml, robots.txt (metadata files)
     */
    {
      source: '/((?!api|_next/static|_next/image|favicon.ico|sitemap.xml|robots.txt|.*\\.json).*)',
      missing: [
        { type: 'header', key: 'next-router-prefetch' },
        { type: 'header', key: 'purpose', value: 'prefetch' },
      ],
    },
  ],
}
