import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import type { DuringCampaignConfig } from '../../../types/MarketingTest/Campaign'

export const useDuringCampaign = (config: DuringCampaignConfig) => {
  const [isDuringCampaign, setIsDuringCampaign] = useState<boolean>(false)
  const router = useRouter()

  useEffect(() => {
    if (!router.isReady || !config) return

    const { campaignId, startDate, endDate } = config
    const currentCampaignId = router.query.utm_id as string

    // 条件を満たしているかをチェック
    if (currentCampaignId === campaignId) {
      const now = new Date()
      if (now >= new Date(startDate) && now <= new Date(endDate)) {
        setIsDuringCampaign(true)
        return
      }
    }

    setIsDuringCampaign(false) // 条件に一致しない場合は無効化を解除
  }, [router.isReady, config])

  return { isDuringCampaign }
}
