import { Box, Text, VStack } from '@chakra-ui/react'

import LogoLgIcon from '@/modules/assets/icon/Brand/LogoLg.svg'
import { ChakraNextImage } from '@/modules/components/ChakraNextImage'

export const LogoAndTitleElm = ({ title }: { title?: string }) => {
  return (
    <VStack p="24px" spacing="16px" w="100%">
      <Box height="60px" width="128px">
        <ChakraNextImage alt="logo" src={LogoLgIcon.src} />
      </Box>
      {title && (
        <Text fontSize="16px" fontWeight={700} lineHeight="150%">
          {title}
        </Text>
      )}
    </VStack>
  )
}
