import type { ABTestConfig, DuringCampaignConfig } from '../../types/MarketingTest/Campaign'

export const DISABLE_COMPONENT_CAMPAIGN = {
  campaignId: '21978043398',
  startDate: '2025-02-01T00:00:00',
  endDate: '2025-02-04T23:59:59',
} as const satisfies DuringCampaignConfig

export const LINE_BUTTON_SWITCHER_CAMPAIGN = {
  testName: 'line_btn_switcher_test',
  campaignId: '21978043398',
  startDate: '2025-01-29T00:00:00',
  endDate: '2025-02-04T23:59:59',
  variantRatio: 0.5,
  eventNameTypeA: 'view_line_login_button_emphasize',
  eventNameTypeB: 'view_line_login_button_default',
} as const satisfies ABTestConfig
