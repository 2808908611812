import Head from 'next/head'

import { LoginPageWrapper } from '@/components/auth/LoginPageWrapper'
import { META } from '@/const/meta'

const Login: React.FC = () => {
  return (
    <div>
      <Head>
        <title>{META.login.title}</title>
        <meta content={META.login.description} name="description" />
        <meta content={META.login.title} property="og:title" />
        <meta content={META.login.description} property="og:description" />
      </Head>
      <LoginPageWrapper />
    </div>
  )
}

export default Login
