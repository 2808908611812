/**
 * このファイルはlocal storageのkeyを定義するファイルです。
 * 同一オリジン（プロトコル、ドメイン、ポート）で一意の命名が必要
 */
export const localStorageKey = {
  INQUIRY_OF_JOB: 'inquiryOfJob',
  INQUIRY_OF_SCOUT: 'inquiryOfScout',
  JOB_ID: 'jobId',
  QUESTION_ABOUT_ID: 'questionAboutId',
  SEARCH_URL_PARAMS: 'searchUrlParams',
  IS_LOGGED_IN: 'isLoggedIn',
  LOGIN_COMPLETE: 'loginComplete',
  TO_URL_AFTER_LOGIN: 'toUrlAfterLogin',
} as const

export const FEEDBACK_COMPLETED = 'feedbackCompleted'
